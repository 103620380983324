$(->
  $id = $('body').attr('id')
  $class = $('body').attr('class')

  $id is 'failuree' and $('.popup').qtip({
    content: '<img src="../img/failure_about_capsular_contracture.gif" alt="$B%+%W%;%k94=L$H$O(B" />'
    position: {
      corner: {
        target: 'bottomRight'
        tooltip: 'topLeft'
      }
    }
    style: {
      padding: 0
      border: {
        width: 0
      }
      width: 330
      height: 200
    }
  })

  fix = $('#sub-fix-contents')
  side = $('#sub')
  main = $('#main')
  sideTop = side.offset().top
  fixTop = fix.offset().top
  mainTop = main.offset().top
  w = $(window)

  adjust = ->
    fixTop = if fix.css('position') is 'static' then sideTop + fix.position().top else fixTop
    fixHeight = fix.outerHeight(true)
    mainHeight = main.outerHeight()
    winTop = w.scrollTop()
    if winTop + fixHeight > mainTop + mainHeight
      fix.removeClass('is-fixed')
      fix.addClass('is-bottom')
    else if winTop >= fixTop
      fix.addClass('is-fixed')
      fix.removeClass('is-bottom')
    else
      fix.removeClass('is-fixed')
    return

  w.on('scroll', adjust)

  if $id is 'siliconbag'
    $('.m-tab-item').find('a').on('click'
      (e)->
        e.preventDefault()

        $('.m-tab-item').removeClass('is-active')
        $('.m-tab-item').each(
          ()->
            $(this).find('img').attr('src', $(this).find('img').attr('src').replace('_a.gif', '.gif'))
        )
        $(this).parent('.m-tab-item').addClass('is-active')
        $(this).find('img').attr('src', $(this).find('img').attr('src').replace('.gif', '_a.gif'))

        $('.m-tab-contents').removeClass('is-active')
        $($(this).attr('href')).addClass('is-active')
    )
)
